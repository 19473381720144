import React from "react";

function Func() {
  
  function toggleFix() {
    if (window.scrollY !== 'undefined') {
      if ((window.scrollY < 50) && document.body.classList.contains('fix-menu')) {
        document.body.classList.remove("fix-menu");
      } 
      if ((window.scrollY > 50) && !document.body.classList.contains('fix-menu')) {
        document.body.classList.add("fix-menu");
      }
    }
  };

  if (window.innerWidth > 1024)  window.addEventListener("scroll", toggleFix);

};
export default Func;