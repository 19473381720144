export const project = {
    name: 'mfaktoring',
    longName: 'mFaktoring'
}
export const environment = {
    projectName: project.longName,
    cmsName: 'cms-'+project.name,
    localhost: 'localhost',
    port: '3003',
    cookieName: 'mfk',
    devName: 'test-'+project.name,
    testName: 'test-'+project.name,
    prodName: 'prod-'+project.name,
    siteSites: '/sites/'+project.name+'/',
	siteUrlDev: 'https://test-'+project.name+'.qrqs.eu',
    siteUrlTest: 'https://test-'+project.name+'.qrqs.eu',
    siteUrlProd: 'https://prod-'+project.name+'.qrqs.eu',
    siteCMSDev: 'https://test-cms-'+project.name+'.qrqs.eu',
    siteCMSTest: 'https://test-cms-'+project.name+'.qrqs.eu',
    siteCMSProd: 'https://prod-cms-'+project.name+'.qrqs.eu',
    cmsPageUrl: '/json/sites/'+project.name,
    page: 'index.html',
    pageJson: 'index.html.json',
    error404Page: '/.errors/404.html',
    error404Json: '/.errors/404.html.json',
    navJson: '/.includes/nav/nav-json.json',
    categoriesJson: '/.includes/categories/categories.json',
    galleryJson: '/images/galleries/list.json',
    searchPath: 'https://search.interconsystems.pl/s/1910EC/default/',
    searchNewsApi: '/api/jsonws/news/search/',
    searchProm: '/.includes/search/search-main.csv',
	reCaptchaKey: '6L>>>6L6L>>>6L6L>>>6L6L>>>6L',
    defaultImage: '/.templates/img/logo/herb.png',
    qrqsLink: 'https://qrqs.eu/',
    iconsFolder: '/.templates/img/icons/',
    loginUrl: 'https://cm.mfaktoring.pl/clientmgr-aquariuslive/'
};
