import React, { useState } from "react";
import { environment } from "./../../environment";
import { px } from "@mbank-design/design-system/utils";
import { Spacing } from "@mbank-design/design-system/enums";
import { Box } from "@mbank-design/design-system/components";
import { Text, Image } from "@mbank-design/design-system/components";
import { Disclosure } from "@mbank-design/design-system/components";
import styled from "styled-components";
import { Container } from "@mbank-design/design-system/components";
import generateImageUrl from "../../utils/GenerateImageUrl";

const CustomContainer = styled(Container)`
  max-width: ${px(1315)} !important;
`;

const ItemBoxWrapper = styled.div`
  display: flex;
  gap: 24px;
  justify-content: flex-start;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    img {
      max-width: 420px;
    }
  }
`;

const ItemWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media screen and (min-width: 992px) {
    flex: 0 1 auto;
    flex-basis: 33.33%;
    text-align: ${props => props.isImage ? 'left' : 'center'};
    align-items: ${props => props.isImage ? 'flex-start' : 'center'};

    &:after {
    position: absolute;
    bottom: 0;
    top: 0;
    right: -12px;
    width: 3px;
    background-color: #565a69;
    content: '';
    display: ${props => props.isImage ? 'none' : 'block'}
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
  }
`;

const Inner = styled.div`
text-align: ${props => props.isImage ? 'left' : 'center' };
  @media screen and (max-width: 991px) {
    text-align: center;
  }
`;

const ManagementBox = (params) => {
  if (
    window.origin.includes(environment.localhost) ||
    window.origin.includes(environment.devName)
  ) {
    console.log("ManagementBox");
    console.log(params);
  }

  function ManagementBoxItem({ item }) {
    const [isOpen, setIsOpen] = useState(false);
    let altText = "";
    if (item.AltText) {
      altText = item.AltText;
    } else if (!item.AltText && item.Title) {
      altText = item.Title;
    } else {
      altText = "mFaktoring image";
    }
    
    return (
      <ItemWrapper itemCount={params.block["en"].Item.length} isImage={item.Image ? true : false}>
        {item.Image && 
          <Image src={generateImageUrl(item["Image"])} alt={altText} maxWidth="100%" title={altText} />
        }
        <Box marginTop={px(24)}>
          <Inner itemCount={params.block["en"].Item.length} isImage={item.Image ? true : false}>
            {item.Title && (
              <Text as="h3" style={{ marginBottom: px(Spacing.SPACE_8) }}>
                {item.Title}
              </Text>
            )}
            {item.SubTitle && (
              <Text as="h4" style={{ marginBottom: px(Spacing.SPACE_16) }}>
                {item.SubTitle}
              </Text>
            )}
            <Disclosure
              isDefaultExpanded={false}
              onChange={() => {
                setIsOpen((prev) => !prev);
              }}
            >
              <Disclosure.Content>
                {item.Text && (
                  <Box dangerouslySetInnerHTML={{ __html: item.Text }}></Box>
                )}
              </Disclosure.Content>
              {item.Text && 
                <Disclosure.Button>
                  {isOpen ? "ukryj opis" : "pokaż opis"}
                </Disclosure.Button>
              }
            </Disclosure>
          </Inner>
        </Box>
      </ItemWrapper>
    );
  }
  
  return (
    <Box as="section" className="management-box" marginTop={Spacing.SPACE_24}>
      <CustomContainer>
        {params.block["en"]["Title"] && (
          <Text as="h2" marginBottom={Spacing.SPACE_24}>
            {params.block["en"]["Title"]}
          </Text>
        )}
        <ItemBoxWrapper itemCount={params.block["en"].Item.length}>
          {params.block["en"].Item.map((item, index) => (
            <ManagementBoxItem key={index} item={item} />
          ))}
          {params.block["en"].Item.length == 2 && <div style={{flexBasis: '33.33%'}}></div>}
        </ItemBoxWrapper>
      </CustomContainer>
    </Box>
  );
};

export default ManagementBox;
