import React from "react";
import { createRoot } from "react-dom/client";
import { environment } from "./environment";
import TopBannerCarousel from "./portlets/topBannerCarousel/TopBannerCarousel";
import TextBox from "./portlets/textBox/TextBox";
import VideoBox from "./portlets/video-box/VideoBox";
import ListBox from "./portlets/listBox/ListBox";
import NewsTeaser from "./portlets/news/NewsTeaser";
import NewsDetails from "./portlets/news/NewsDetails";
import Galleries from "./portlets/galleries/Galleries";
import SearchBox from "./portlets/searchBox/SearchBox";
import AccordionList from "./portlets/accordion/Accordion";
import ManagementBox from "./portlets/managementBox/ManagementBox";
import Banner from "./portlets/banner/Banner"
import {
    DesignSystemThemeProvider,
    DesignSystemOverlayProvider,
    DesignSystemSnackbarProvider,
  } from '@mbank-design/design-system/providers';
  import {mBankCorporate} from '@mbank-design/design-system/themes';
  import { BrowserRouter } from "react-router-dom";
import InfoboxSet from "./portlets/infoboxSet/InfoboxSet";
import ButtonBox from "./portlets/buttonBox/ButtonBox";
import DocumentBox from "./portlets/documentBox/DocumentBox";

const Components = {
    "top-banner-carousel": TopBannerCarousel,
    "text-box": TextBox,
    "video-box": VideoBox,
    "news-details": NewsDetails,
    "news-details-teaser": NewsTeaser,
    "galleries": Galleries,
    "search-box": SearchBox,
    "accordion": AccordionList,
    "management-box": ManagementBox,
    "infobox-set": InfoboxSet,
    "banner": Banner,
    "button-box": ButtonBox,
    "document-box": DocumentBox,
};

function Portlets(line, block, pageData, index, locale) {
    if (line === 'online') {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
            console.log('Portlets list www')
            console.log(block)
        }
        if (typeof pageData !== "undefined" && !block.hasOwnProperty('exception')) {
            if (block.hasOwnProperty('listInfo')) {
                if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
                    console.log('potlet list box');
                }
                //must be wrapped into LIST portlet
                let key = pageData.split("/").pop();
                key = key.substring(0, key.length - 4);
                return <ListBox block={block} line={line} key={key + '_' + index} />
            }
            else {
                if (block.hasOwnProperty('attributes') && block.attributes.hasOwnProperty('type')) {
                    let type = block.attributes.type
                    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
                        console.log('portlet type - ' + type)
                    }
                    if (Components.hasOwnProperty(type)) {
                        let key = pageData.split("/").pop();
                        key = key.substring(0, key.length - 4);
                        return React.createElement(Components[type], {
                            key: key + '_' + index,
                            block: block,
                            line: line
                        });
                    }
                }
                else {console.error('portlet type - is undefined')}
            }
        }
    }
    if (line === 'offline') {
        function CreateComp(portlet, name, index) {
            const Portlet = Components[name];
            return (
                <DesignSystemThemeProvider theme={mBankCorporate}>
                    <DesignSystemOverlayProvider>
                        <DesignSystemSnackbarProvider>
                            <BrowserRouter>
                                <Portlet block={portlet} key={index} line={line} />
                            </BrowserRouter>
                        </DesignSystemSnackbarProvider>
                    </DesignSystemOverlayProvider>
                </DesignSystemThemeProvider>
            );
        }
        let listComp = []
        block.map((cont, i) => {
            //const type = cont.type; //get container info
            //const id = cont.name; //may be usefull later
            cont.elements.map((item, ii) => {
                const uuid = item.formatterKey;
                const path = item.path;
                listComp.push({ uuid, path })
                return null;
            })
            return null;
        })
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
            console.log('Portlets list cms')
            console.log(pageData.linkedContents)
        }

        listComp.map((elem, i) => {
            let portlet = pageData.linkedContents[elem.path]
            if (!portlet.hasOwnProperty('exception')) {
                if (portlet.hasOwnProperty('list')) {
                    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
                        console.log('potlet list box');
                    }
                    portlet.list.map((teaser, i) => {
                        let type = teaser.attributes.type;
                        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
                            console.log('portlet type - ' + type)
                        }
                        if (Components.hasOwnProperty(type)) {
                            let id = teaser.path.split("/").pop();
                            id = id.substring(0, id.length - 4);
                            let teaserId = document.getElementById(id);
                            if (teaserId) {
                                let box = createRoot(teaserId);
                                box.render(
                                    CreateComp(teaser, type + '-teaser', id + "_" + i)
                                )
                            }
                        }
                        return null;
                    })
                }
                else {
                    if (portlet.hasOwnProperty('attributes')) {
                        if (portlet.attributes.hasOwnProperty('type')) {
                            let type = portlet.attributes.type;
                            if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
                                console.log('portlet type - ' + type)
                            }
                            if (Components.hasOwnProperty(type)) {
                                let id = elem.path.split("/").pop();
                                id = id.substring(0, id.length - 4);
                                let portletId = document.getElementById(id);
                                if (portletId) {
                                    let box = createRoot(portletId);
                                    box.render(
                                        CreateComp(portlet, type, id + "_" + i)
                                    )
                                }
                            }
                        }
                    }
                }
            }
            return null;
        });
    }
};
export default Portlets;