import React from "react";
import { Link } from "react-router-dom";
import { environment } from "./../environment";
import { Button } from '@mbank-design/design-system/components';

function OurButton(params) {
    let isDetails = '';
    
        if (params.isdetails) {
            isDetails = params.isdetails
        } else {
            isDetails = false;
        }

    let link = '';
    if ((params.href !== '' && params.href) && isDetails === false) {
        if (params.href.hasOwnProperty('link')) {
            link = params.href.link.toString().replace(environment.siteSites, '');
        }
        else {
            link = params.href.toString().replace(environment.siteSites, '');
        } 
    }
    if ((params.href !== '' && params.href) && isDetails === true) {
        link = params.href
        if (link.includes(environment.siteSites)) {
            link = link.split(environment.siteSites);
            link = link[1];
        }
    }
    let newtab = "";
    if (params.addtarget === 'true' && params.addtarget) {
        newtab = { 'target': '_blank' }
    }
    let title = "";
    if (params.addtitle !== '' && params.addtitle) {
        title = { 'title': params.addtitle }
    }
    let addclasss = "";
    if (params.addclass !== '' && params.addclass) {
        addclasss = " " + params.addclass;
    }
    let iconhtml = '';
    if (params.addiconhtml !== '' && params.addiconhtml) {
        iconhtml = (
            params.addiconhtml
        )
    }
    let icon = '';
    if (params.addicon !== '' && params.addicon) {
        icon = (
            <span className={params.addiconclass}>
                {iconhtml}
            </span>
        )
    }
    let button = '';
    let classNames = 'button ' + params.addtype + addclasss;
    let typeButton = params.addtype;
    if (link !== '' && params.addname !== '' && params.addname) {
        if (params.line === 'offline' || link.includes("http://") || link.includes("https://")) { //not working on WWW
            button = (
                <Button size="normal" buttonType={typeButton} component="a" href={link} {...title} {...newtab}> 
                    {params.addname}
                </Button>
            )
        }
        else {
            button = (
                <Button component={Link} to={link}>
                    {params.addname}
                </Button>
            )
        }
    }

    return (
        <>
            {button}
        </>
    );
}

export default OurButton;