import React, { useRef, useState } from 'react';
import {Button} from '@mbank-design/design-system/components';
import { Box, Menu } from '@mbank-design/design-system/components';
import styled from 'styled-components';

function Container(params) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const downloadButtonRef = useRef(null);

	const AtagGrey = styled.a`
		color: #565a69;
		font-weight: bold;
	`;
	const AtagBlue = styled.a`
		color: #184868;
		font-weight: bold;
	`;
	function handleOnDownloadButtonClick() {
		setIsMenuOpen(true);
	}
	function handleOnMenuClose() {
		setIsMenuOpen(false);
	}
	function handleOnMenuItemClick() {
		setIsMenuOpen(false);
	}
    return (
		<Box>
			<Button
				buttonType='secondary' 
				ref={downloadButtonRef}
				onClick={handleOnDownloadButtonClick}
				accessibilityLabel={isMenuOpen ? 'Close menu' : 'Open menu'}
			>
				zaloguj
			</Button>
				<Menu
					isOpen={isMenuOpen}
					anchor={downloadButtonRef.current}
					anchorOriginHorizontal="right"
					onClose={handleOnMenuClose}
				>
				<Box>
					<Menu.Item onClick={handleOnMenuItemClick} >
						<AtagGrey href="https://cm.mfaktoring.pl/clientmgr-aquariuslive/" target="_blank">Strefa klienta</AtagGrey>
					</Menu.Item>
					<Menu.Item onClick={handleOnMenuItemClick}>
						<AtagBlue href="https://portal.mfaktoring.pl/" target="_blank">Portal klienta</AtagBlue>
					</Menu.Item>
				</Box>
			</Menu>
		</Box>
    );
}

export default Container;