import React from "react";
import { environment } from "./../../environment";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Box,
  Container,
  Text,
  Button,
} from "@mbank-design/design-system/components";
import { Spacing } from "@mbank-design/design-system/enums";
import { px } from "@mbank-design/design-system/utils";

const CustomContainer = styled(Container)`
  max-width: ${px(1315)} !important;
`;

const CustomContainerInner = styled(Container)`
  background-color: ${(props) => (props.isBackground ? "#f6f6f6" : "none")};
`;

const ButtonBox = (params) => {
  if (
    window.origin.includes(environment.localhost) ||
    window.origin.includes(environment.devName)
  ) {
    console.log("ButtonBox");
    console.log(params);
  }
  return (
    <Box as="section" className="button-box" marginTop={Spacing.SPACE_24}>
      <CustomContainer>
        <CustomContainerInner isBackground={params.block["en"].InnerBackground}>
          {params.block["en"].Title && (
            <Text as="h2" marginBottom={Spacing.SPACE_24} align="center">
              {params.block["en"].Title}
            </Text>
          )}
          <Box display="flex" alignItems="center" justifyContent="center" padding={24}>
            {params.block["en"]["ButtonLink"] && params.block["en"]["ButtonName"] && !params.block["en"].InNewOpen &&
              <Button component={Link} to={params.block["en"]["ButtonLink"].link}>
                {console.log(params.block["en"]["ButtonLink"].link)}
                {params.block["en"]["ButtonName"]}
              </Button>
            }

            {params.block["en"]["ButtonLink"] && params.block["en"]["ButtonName"] && params.block["en"].InNewOpen &&
              <Button component="a" href={params.block["en"]["ButtonLink"].link} target={params.block["en"].InNewOpen ? '_blank' : ''}>
                {console.log(params.block["en"]["ButtonLink"].link)}
                {params.block["en"]["ButtonName"]}
              </Button>
            }
            
          </Box>
        </CustomContainerInner>
      </CustomContainer>
    </Box>
  );
};

export default ButtonBox;
