import React, { useState } from "react";
import { environment } from "./environment";
import Nav from "./Nav.js";
import useWindowDimensions from "./Device.js";

import styled from 'styled-components';
import {MbankFaktoringCorporate as Logo} from '@mbank-design/design-system/logos';

import Actions from './components/Actions';

import {Stripe, Container} from '@mbank-design/design-system/components';
import {Icon, Link} from '@mbank-design/design-system/components';
import { Link as ReactRouterLink } from 'react-router-dom';
import {IconColor} from '@mbank-design/design-system/enums';
import {ButtonBase} from '@mbank-design/design-system/components';
import {HamburgerMenu} from '@mbank-design/design-system/icons';
import {Cross} from '@mbank-design/design-system/icons';
import {StripeHeight} from '@mbank-design/design-system/enums';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';

let hostname = '';
if (window.location.origin.includes(environment.cmsName)) {
	hostname = window.location.origin;
  }
  else if (window.location.origin.includes(environment.localhost)) {
	hostname= environment.siteUrlDev;
  }
  else {
	hostname = window.location.origin;
  }
const MainNav = styled.nav`
	display: flex;
	align-items: center;
	padding-bottom: ${px(Spacing.SPACE_16)};
	padding-top: ${px(Spacing.SPACE_16)};
	margin: 0 auto;
	max-width: ${px(1315)};
	padding-right: ${px(Spacing.SPACE_24)};
	padding-left:  ${px(Spacing.SPACE_24)};
	@media screen and (max-width: 1363px) {
		padding-right: 0;
		padding-left: 0;
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: 0;
	}
`;
const NavToggle = styled.div`
	display: none;
	justify-content: center;
	align-items: center;
	height: ${px(Spacing.SPACE_50)};
	width: ${px(Spacing.SPACE_50)};
	margin-left: auto;
	cursor: pointer;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: flex;
		position: absolute;
		right: ${px(Spacing.SPACE_16)};
	}
`;
const Menu = styled.ul`
	list-style: none;
	display: flex;
	margin-right: auto;
	padding-left: ${px(Spacing.SPACE_56)};

	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1285px) {
		padding-left: ${px(Spacing.SPACE_24)};
	}
	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1130px) {
		padding-left: 14px;
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		width: 100%;
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
		margin-top: ${Spacing.SPACE_0};
		margin-bottom: ${Spacing.SPACE_0};
	}
`;
const MenuItem = styled.li`
	margin-left: ${props => props.marginLeft || px(Spacing.SPACE_12)};
	margin-right: ${props => props.marginRight || px(Spacing.SPACE_12)};
	margin-bottom: ${props => props.marginBottom || 0};

	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1130px) {
		margin-left: 10px;
		margin-right: 10px;
		
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		position: relative;
		margin-right: ${Spacing.SPACE_0};
		margin-left: ${Spacing.SPACE_0};
		margin-bottom: ${Spacing.SPACE_0};
		padding-top: ${px(Spacing.SPACE_12)};
		padding-bottom: ${px(Spacing.SPACE_12)};
		border-bottom: 1px solid ${palette.altoGray};
	}
`;

// toggle visibility based on component props
const SubmenuLayer = styled.div`
	${props => {
		if (props.show) {
			return `
				display: block;
				position: absolute;
				top: ${px(Spacing.SPACE_84)};
				right: ${px(Spacing.SPACE_0)};
				left: ${px(Spacing.SPACE_0)};
				z-index: 2;
				padding-top: ${px(Spacing.SPACE_40)};
				padding-bottom: ${px(Spacing.SPACE_40)};
				background-color: ${palette.solidWhite};
			`;
		} else {
			return `
				display: none;
				position: absolute;
				top: ${px(Spacing.SPACE_84)};
				right: ${px(Spacing.SPACE_0)};
				left: ${px(Spacing.SPACE_0)};
				z-index: 2;
				padding-top: ${px(Spacing.SPACE_40)};
				padding-bottom: ${px(Spacing.SPACE_40)};
				background-color: ${palette.solidWhite};
			`;
		}
	}}

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		position: fixed;
		top: ${px(Spacing.SPACE_84)};
		bottom: ${px(Spacing.SPACE_0)};
		padding-left: ${px(Spacing.SPACE_16)};
		padding-right: ${px(Spacing.SPACE_16)};
	}
`;
const Overlay = styled.div`
	${props => {
		if (props.show) {
			return `
				display: block;
				position: fixed;
				top: ${px(Spacing.SPACE_84)};
				right: ${px(Spacing.SPACE_0)};
				left: ${px(Spacing.SPACE_0)};
				z-index: 1;
				height: ${props.siteHeight + 'px'};
				background-color: ${palette.zeusBlackUI};
				opacity: .2;
			`;
		} else {
			return `
				display: none;
				position: absolute;
				top: ${px(Spacing.SPACE_84)};
				right: ${px(Spacing.SPACE_0)};
				left: ${px(Spacing.SPACE_0)};
				z-index: 1;
				height: ${props.siteHeight + 'px'};
				background-color: ${palette.zeusBlackUI};
				opacity: .2;
			`;
		}
	}}

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		top: 0;
	}
`;
const Submenu = styled.ul`
	list-style: none;
	padding-left: ${px(Spacing.SPACE_0)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: ${props => props.open ? 'block' : 'none'};
	}
`;
const SubmenuWrapper = styled.div`
	display: flex;
	padding-left: ${props => props.index === 0 ? px(223) : px(395)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-wrap: wrap;
		padding-left: ${px(Spacing.SPACE_0)};
	}
`;
const CustomContainer = styled(Container)`
	max-width: ${px(1315)} !important;
`;
const ButtonContent = styled.span`
	position: relative;
	display: flex;
	color: ${props => props.color};
	padding-right: ${px(Spacing.SPACE_20)};
`;
const MobileButtonContent = styled(ButtonContent)`
	display: none;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
	}
`;
const IconWrapper = styled.div`
	position: absolute;
	left: calc(100% - ${px(Spacing.SPACE_20)});

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const MobileIconWrapper = styled.div`
	position: absolute;
	display: none;
	left: calc(100% - ${px(Spacing.SPACE_24)});
	top: ${px(Spacing.SPACE_12)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
	}
`;
const SubmenuColumn = styled.div`
	flex-basis: 50%;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-basis: 100%;
	}
`;
const SubmenuTitle = styled.div`
	display: inline-block;
	margin-bottom: ${px(Spacing.SPACE_24)};
	padding-bottom: ${px(Spacing.SPACE_12)};
	border-bottom: ${px(2)} solid ${palette.endeavourBlueUI};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const MobileSubmenuTitle = styled.div`
	display: none;
	margin-right: ${Spacing.SPACE_0};
	margin-left: ${Spacing.SPACE_0};
	padding-top: ${px(Spacing.SPACE_12)};
	padding-bottom: ${px(Spacing.SPACE_12)};
	border-bottom: 1px solid ${palette.altoGray};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		position: relative;
		display: ${props => props.open ? 'block' : 'none'};
	}
`;
const MobileBackLink = styled.div`
	display: none;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
	}
`;
const NavWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	align-items: center;
	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1285px) {
		font-size: 0.8em;
	}
	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1130px) {
		font-size: 0.7em;
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: ${props => props.open ? 'flex' : 'none'};
		flex-direction: column;
		position: absolute;
		top: ${px(87)};
		left: 0;
		right: 0;
		height: calc(100vh - ${props => props.offline ? px(87) : px(87)});
		background-color: ${palette.solidWhite};
		z-index: 2;
	}
`;
const NavButton = styled(ButtonBase)`
	font-weight: 600;
	font-family: Montserrat,Arial,sans-serif;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		width: 100%;
	}
`;

function Header(params) {
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log('Header');
    console.log(params)
  }

  const [mobMenuOpen, setMobMenuOpen] = useState(false);

  const { device } = useWindowDimensions();
  function OpenMenu() {
    const sidemenu = document.getElementById("sidemenu");
    if (sidemenu.classList.contains('open')) {
      sidemenu.classList.remove("open");
      CloseSide()
    }
    else if (!sidemenu.classList.contains('open')) {
      sidemenu.classList.add("open");
    }
  };
  function CloseMenu() {
    const sidemenu = document.getElementById("sidemenu");
    if (sidemenu.classList.contains('open')) {
      sidemenu.classList.remove("open");
      CloseSide()
    }
  };
  function CloseSide() {
    const sidenav = document.getElementById("sidenav");
    if (sidenav.classList.contains('show')) {
      sidenav.classList.remove("show");
    }
  };
	function toggleMobileNav () {
		setMobMenuOpen(!mobMenuOpen)
	}
    return (
		<>
			<Stripe height={StripeHeight.HEIGHT_4} />
			<Container>
				<MainNav className="main-nav">
					<Link
						component={ReactRouterLink}
						to={'/'}
					>
						<Logo width="155" height="50" title="logo" />
					</Link>
					<NavToggle className="nav-toggle" onClick={toggleMobileNav}>
						<Icon iconComponent={mobMenuOpen ? Cross : HamburgerMenu} primaryColor={IconColor.BLUE} title="menu" />
					</NavToggle>
					<NavWrapper open={mobMenuOpen} offline={'offline'}>
						<Menu className="menu"><Nav line={params.line}/></Menu>
						<Actions offline={'offline'} prefix={'prefix'} />
					</NavWrapper>
				</MainNav>
			</Container>
		</>
    );
  
}

export default Header;