import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ImageGallery from 'react-image-gallery';
import { createRoot } from "react-dom/client";
import { environment } from "./../../environment";
import Loaders from "../../Loaders.js";
import Svg from "../../components/Svg";

function NewsGalleries(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('News Galleries')
        console.log(params)
    }
    let [galleriesData, setGalleriesData] = useState("");
    useParams();
    let [jsonStatus, setJsonStatus] = useState(null),
        FullScreen = false
    useEffect(() => {
        let fetchGalleries = async () => {
        let url = null;
        if (window.location.origin.includes(environment.cmsName)) {
            if (window.location.pathname.includes("/item/")) {
                switch (window.location.origin) {
                    case environment.siteCMSDev:
                      url = environment.siteUrlDev
                      break;
                    case environment.siteCMSTest:
                      url = environment.siteUrlTest
                      break;
                    case environment.siteCMSProd:
                      url = environment.siteUrlProd
                      break;
                    default:
                      break;
                  }
                url = url + "/images/galleries/list.json";
            }
            else {
                url = window.location.origin + "/images/galleries/list.json";
            }
        }
        else if (window.location.origin.includes(environment.localhost)) {
            url = environment.siteUrlDev + '/images/galleries/list.json';
        }
        else {
            url = window.location.origin + '/images/galleries/list.json';
        }
        let response = "";
        try {
            response = await Axios(url);
            setJsonStatus(true);
        } catch (err) {
            setJsonStatus(false);
            response = "error";
        }
        let galleriesSort = []
        if (response.hasOwnProperty('data')) {
            Object.keys(response.data).map(function(key) {
                let linkImg = '',
                    thumbnail = '';
                params.folders.map((folder, index) => {
                    let name = ""
                    if (folder.includes('/images/galleries/')) {
                        name = folder.split('/images/galleries/');
                        name = name[1];
                    } else {
                        name = folder.split('/');
                        name = name[name.length - 2];
                    }
                    let item = response.data[name];
                    if (item) {
                        let path = item.path ? item.path : item.link
                        if (path.includes(environment.siteSites)) {
                            path = path.split(environment.siteSites);
                            path = path[1];
                        }
                        if (window.origin.includes(environment.localhost)) {
                            linkImg = environment.siteUrlDev + path;
                        } 
                        else {
                            linkImg = window.origin + path;
                        }
                        let categories = [];
                        item.categories.map(item => {
                            let i = item.split('/');
                            let tag = i[i.length - 2];
                            categories.push(tag);
                            return null;
                        })
                        
                        if (item.own_properties["default-file"]) {
                            thumbnail = path + item.own_properties["default-file"] + '_width='+params.tW;
                        } else {
                            thumbnail = path + item.properties["default-file"] + '_width='+params.tW;
                        }

                        galleriesSort.push({'urlGallery': linkImg, 'thumbnail': thumbnail, 'fullscreen': linkImg, 'title': item.own_properties.Title, 'tags': categories})
                    }
                    return null
                })
                
                return null
            })
            // remove same array galleriesSort
            const galleriesSortNoRepeat = galleriesSort.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                   t.title === thing.title
                ))
            )
            setGalleriesData(galleriesSortNoRepeat);
        }
        else {
            setJsonStatus(false);
        }
        };
        fetchGalleries();
    },[]);

    function ImageError(elem) {
        let span = document.createElement("div");
        span.classList.add("error-image");
        elem.before(span);
        const root = createRoot(span);
        root.render(
            <Svg name="preimage1" />
        );
    }
    function ImageLoad(elem) {
        let idl = document.getElementById(elem);
        if (idl) idl.remove()
    }
    function RenderImage(elem, type) {
        if (type === 'thumb') {
            let id = elem.thumbnail.split('/'),
                last = id[id.length - 1].replace('.jpg_width=92',''),
                idL = 'ith_'+last
            return (
                <span className="image-gallery-thumbnail-inner">  
                    <div className="before-image" id={idL}><Loaders type="dots" color="b-black" /></div>
                        <img src={elem.thumbnail} className='image-gallery-thumbnail-image' loading="lazy" alt={elem.description} title={elem.description} 
                            onError={(e) => {ImageError(e.target); ImageLoad(idL, 'err')}}
                            onLoad={(e) => {ImageLoad(idL, 'load')}} 
                        />
                </span>
            )
        }
        else {
            let id = FullScreen ? elem.fullscreen.split('/') : elem.original.split('/'),
                last = id[id.length - 1].replace('.jpg_width=1200',''),
                idL = 'ig_'+last
            return (
                <>  
                    <div className="before-image" id={idL}><Loaders type="circle" mode="true" color="b-t" /></div>
                    <img src={FullScreen ? elem.fullscreen : elem.original} className='image-gallery-image' loading="lazy" alt={elem.description} title={elem.description} 
                        onError={(e) => {ImageError(e.target); ImageLoad(idL, 'err')}}
                        onLoad={(e) => {ImageLoad(idL, 'load')}} 
                    />
                </>
            )
        }
    }
    function RenderImageThumb(params) {
        const id = params.src.split('/');
        const last = id[id.length - 1].split('.jpg','')
        let idL = 'imth_'+last[0]
        return (
            <>  
                <div className="before-image" id={idL}><Loaders type="circle" mode="true" color="b-t" /></div>
                <img src={params.src} className='thumbnail-home' loading="lazy" alt={params.alt} title={params.alt} 
                    onError={(e) => {ImageError(e.target); ImageLoad(idL, 'err')}}
                    onLoad={(e) => {ImageLoad(idL, 'load')}} 
                />
            </>
        )
    }
    function renderGallerySlider(e) {
        const gallery = [];
        let link = "",
            linkImg = "";
        if (params.line === 'offline') { 
            link = window.origin + environment.cmsPageUrl + e;
        } else {
            link = e + 'list.json';
        }
        fetch(link)
            .then(response => response.json())
            .then(data => {
                const items = Object.values(data)
                items.map(item => {
                    let path = item.path ? item.path : item.link
                    if (path.includes(environment.siteSites)) {
                        path = path.split(environment.siteSites);
                        path = path[1];
                    }
                    if (window.origin.includes(environment.localhost)) {
                        linkImg = environment.siteUrlDev + path;
                    } 
                    else {
                        linkImg = window.origin + path;
                    }

                    let thumbnail = linkImg + '_width=92';
                    gallery.push({'original': linkImg+ '_width=1200', 'thumbnail': thumbnail, 'fullscreen': linkImg, 'description': item.properties.Title, 'originalTitle': item.properties.Title, 'loading': 'lazy'})

                    return null
                })
                if (gallery && gallery.length > 0 ) {
                    let sliderBox = document.getElementById("sliderRender"),
                        slider = createRoot(sliderBox);
                    slider.render(
                        <>
                            <div className="gallery-overlay" onClick={() => removeGallerySlider()}></div>
                            <div className={'gallery-div container '}>
                                <ImageGallery items={gallery} showIndex={true} originalHeight={600}
                                    renderItem={(elem) => ( 
                                        RenderImage(elem, 'item')
                                    )}
                                    renderThumbInner={(elem) => (
                                        RenderImage(elem, 'thumb')
                                    )}
                                    onScreenChange={(elem) => (
                                        //setFullScreen(elem)
                                        FullScreen = elem
                                    )}
                                />
                            </div>
                        </>
                    );
                }
            })
            .catch(error => {
                console.error('Error: ', error);
                let sliderBox = document.getElementById("sliderRender");
                let slider = createRoot(sliderBox);
                slider.render(
                    <h4 className="mt-15">Galeria nie dostępna</h4>
                );
            });
    }   
    function removeGallerySlider() {
        let sliderBox = document.getElementById("sliderRender");
        let slider = createRoot(sliderBox);
        slider.render(
            <></>
        );
    }
    function RenderGalleryItems() {
        if (galleriesData.length > 0) {
            return galleriesData.map((item, i) => {
                let GalleryUrl = '';
                let linkGallery = item.thumbnail;
                if (linkGallery !== '' && linkGallery.includes(environment.siteSites)) {
                    linkGallery = linkGallery.split(environment.siteSites);
                    linkGallery = linkGallery[1];
                }
                GalleryUrl = item.urlGallery
                if (GalleryUrl !== '' && GalleryUrl.includes(environment.siteSites)) {
                    GalleryUrl = GalleryUrl.split(environment.siteSites);
                    GalleryUrl = GalleryUrl[1];
                }
    
                return (
                    <div className="item mb-20" key={'galleries-0'+i} title={item.title}>
                        <div className="d-flex f-wrap thumbnail">
                            <span onClick={() => renderGallerySlider(GalleryUrl)} className="gallery-url">
                                <RenderImageThumb src={linkGallery} alt={item.title} />
                            </span>
                        </div>
                        {item.title &&
                            <h3>{item.title}</h3>
                        }
                    </div>
                )
            }) 
        }
        else {
            return (<div><span className="c-black tag-item">NIE ZNALEZIONO GALERIE</span></div>)
        }
    }

    function RenderGalleries() {
        return (
            <section className="galleries">
                <div className="all-galleries d-flex f-wrap mt-20 mb-20">
                    <RenderGalleryItems/>
                    <div id="sliderRender"></div>
                </div>
            </section>
        )
    }

    switch (jsonStatus) {
        case null:
            return <div className="Loading-gal"><Loaders type="dots" color="b-black"/></div>;
        case false:
            return <div className="Error-gal"><h3>Nie udało się załadować galerie</h3></div>;
        case true:
            return (
                <RenderGalleries />
            );
        default:
            break;
    }
};
export default NewsGalleries;