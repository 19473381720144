import React, { useState } from 'react';
import { environment } from "./environment";
import { Link as ReactRouterLink } from 'react-router-dom';
import { Box, Link } from '@mbank-design/design-system/components';
import { CookieWidget } from '@mbank-design/design-system/components/experimental';

function Cookies(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Cookies')
        console.log(params)
    }

    let show = true;

    function handleCookiesAccept() {
        document.cookie = "read_cookies=accepted; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure";
    }

    function handleCookiesClosed() {
        document.cookie = "read_cookies=rejected; SameSite=None; Secure";
    }

    if (document.cookie.split(';').some((item) => item.includes('read_cookies=accepted'))) {
        show = false;
    }

    if (show === true) {
        return (
            <Box>
                <CookieWidget key={'cookies'} onAccept={handleCookiesAccept} onClose={handleCookiesClosed}>
                    Zależy nam, aby świadczyć usługi na najwyższym poziomie. Dlatego serwis informacyjny mFaktoring S.A. wykorzystuje pliki cookies, 
                    które zapisują się w pamięci przeglądarki. Pliki cookies (po polsku ciasteczka) to małe pliki tekstowe, które automatycznie tworzy 
                    przeglądarka internetowa, gdy odwiedzasz strony WWW. Informacje w danym pliku cookie może odczytać wyłącznie ta strona, z której 
                    ten plik pochodzi. Co to oznacza dla Ciebie? Strona internetowa nie może mieć dostępu do innych plików, które znajdują się na Twoim komputerze. 
                    Możesz skonfigurować tak swoją przeglądarkę, aby blokowała ona pliki cookies lub usuwała je. Więcej na ten temat przeczytasz w naszym materiale <Link component={ReactRouterLink} to="/bezpieczenstwo-i-prawo/rodo/polityka-prywatnosci/">polityka cookies</Link>. Pamiętaj, że jeśli zablokujesz pliki cookies, nie będziesz mógł, niestety, korzystać 
                    (całkowicie lub z części funkcji) z naszego serwisu internetowego. Jeśli będziesz dalej korzystać z naszych serwisów i nie zmienisz 
                    ustawień plików cookies, uznamy, że zapoznałeś się z tą wiadomością i akceptujesz nasze pliki cookies.
                </CookieWidget>
            </Box>
        )
    }
}

export default Cookies;