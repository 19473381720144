import React from 'react'
import styled from 'styled-components';
import { px } from '@mbank-design/design-system/utils';
import { environment } from "./../../environment";
import { Box, Accordion, Text } from '@mbank-design/design-system/components';
import {Container} from '@mbank-design/design-system/components';

const CustomContainer = styled(Container)`
    max-width: ${px(1315)} !important;
`

const AccordionList = (params) => {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Accordion')
        console.log(params)
    }
    

    function createAccordionSection() {
        return params.block['en']['Section'].map(function (item, i) {
            return (
                <>
                <Text as="h2" paddingTop={48} paddingBottom={24}>{item.Title}</Text>
                <Box display="flex">
                    <Accordion>
                        {createAccordionItems(item.Item)}
                    </Accordion>
                </Box>
                </>
            )
        })
    }

    function createAccordionItems(a) {
        return a.map(function (item, i) {
            return (
                <Accordion.Item>
                    <Accordion.Header level="4">{item.Title}</Accordion.Header>
                    <Accordion.Content>
                        <Text
                        dangerouslySetInnerHTML={
                            {__html: item.Text}
                        }>
                        </Text>
                    </Accordion.Content>
                </Accordion.Item>
            )            
        })
    }

    return (
        <Box as="section" className="accordion">
            <CustomContainer>
                {createAccordionSection()}
            </CustomContainer>
        </Box>
    );
}

export default AccordionList;