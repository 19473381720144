import React from "react";
import { Link } from "react-router-dom";
import parse, { domToReact } from 'html-react-parser';
import styled from 'styled-components';
import { environment } from "./../environment";
import { Text } from '@mbank-design/design-system/components';

const Lists = styled('ul')`
  list-style-type: square;
  margin: 0;
`;

function TextLinks(params) {
    const options = {
      replace: ({ name, attribs, children }) => {
        if (name === 'p') { 
          return <Text as='p'>{domToReact(children)}</Text> 
        }
        if (name === 'ul') { 
          return <Lists>{domToReact(children)}</Lists> 
        }
        else if (name === 'a' && attribs.href) {
            let blank = attribs.target ? 'target="'+attribs.target+'"' : '';
            if (params.line === 'offline' || attribs.href.includes("http://") || attribs.href.includes("https://")) {
              let url = attribs.href
              if (url.includes("offline-")) {
                url = url.split(environment.siteSites);
                url = url[1];
                return <a href={url} className={attribs.class} blank>{domToReact(children)}</a>;
              }
              else {
                return <a href={attribs.href} className={attribs.class} blank>{domToReact(children)}</a>;
              }
            }
            else {
              let url = attribs.href
              if (attribs.href.includes("/dokumenty/") ) {
                if (url.includes("offline-")) {
                  url = url.split(environment.siteSites);
                  url = url[1];
                  return <a href={url} target="_blank" className={attribs.class}>{domToReact(children)}</a>;
                }
                else {
                  return <a href={attribs.href} target="_blank" className={attribs.class}>{domToReact(children)}</a>;
                }
              }
              else if (attribs.href.includes("mailto:")) {
                if (url.includes(environment.siteSites)) {
                  url = url.split(environment.siteSites);
                  url = url[1];
                  return <a href={url} className={attribs.class}>{domToReact(children)}</a>;
                }
                else {
                  return <a href={attribs.href} className={attribs.class}>{domToReact(children)}</a>;
                }
              }
              else {
                if (url.includes("offline-")) {
                  url = url.split(environment.siteSites);
                  url = url[1];
                  return <Link to={url} className={attribs.class}>{domToReact(children)}</Link>;
                }
                else {
                  return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
                }
              }
            }
        }
        if (name === 'img' && attribs.src) {
          let image = attribs.src;
          if (image.includes(environment.siteSites) && image !== "") {
            image = image.split(environment.siteSites);
            image = image[1];
          }
      
          if ( window.location.origin.includes(environment.localhost) && !(image.includes("http://") || image.includes("https://")) ) {
            image = environment.siteUrlDev + image
          }
          if ( window.location.origin.includes('cms-') && !(image.includes("http://") || image.includes("https://")) ) {
            image = environment.siteUrlDev + image
          }

          return <img src={image} className={attribs.class} loading="lazy" alt={attribs.alt}/>;
        }
      }
    };
        
    return parse(params.text, options);
}

export default TextLinks;