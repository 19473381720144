import React from 'react'
import { environment } from "./../../environment";
import styled from "styled-components";
import Links from '../../components/Links';
import {
  Box,
  Container,
  Text,
  Icon,
} from "@mbank-design/design-system/components";
import { Spacing, IconColor } from "@mbank-design/design-system/enums";
import { px } from "@mbank-design/design-system/utils";
import { Download, Download30 } from '@mbank-design/design-system/icons';

const CustomContainer = styled(Container)`
  max-width: ${px(1315)} !important;
`;

const DocumentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media screen and (max-width: 750px) {
    grid-template-columns: 1fr;
  }
`

const DocumentBox = (params) => {

  if (
    window.origin.includes(environment.localhost) ||
    window.origin.includes(environment.devName)
  ) {
    console.log("DocumentBox");
    console.log(params);
  }

  const DocumentItem = ({item}) => {
    return (
      <Box display="flex" alignItems="flex-start" gap={Spacing.SPACE_12}>
        { item.FileLink && item.FileName &&
          <>
            <Icon iconComponent={Download30} primaryColor={IconColor.BLUE} title={"dokument"} />
            <Box><Links url={item.FileLink} name={item.FileName} title={item.FileName} /></Box>
          </>
        }
      </Box>
    )

  }

  return (
    <Box as="section" className="document-box" marginTop={Spacing.SPACE_24}>
      <CustomContainer>
        {params.block['en'].Title && 
          <Text as="h2" marginBottom={Spacing.SPACE_24}>
            {params.block["en"].Title}
          </Text>
        }
        <DocumentWrapper>
          {params.block["en"].Item?.map((item, index) => {
            return (
              <DocumentItem item={item} key={index} />
            )
          })}
        </DocumentWrapper>
      </CustomContainer>
    </Box>
  )
}

export default DocumentBox