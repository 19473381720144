import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Links } from "react-router-dom";
import { environment } from "./environment";
import Loaders from "./Loaders.js";


import styled from 'styled-components';
import {Icon, Link, Container} from '@mbank-design/design-system/components';
import { Link as ReactRouterLink } from 'react-router-dom';
import {PhoneHandleRinging} from '@mbank-design/design-system/icons';
import {HumanChat} from '@mbank-design/design-system/icons';
import {Envelope} from '@mbank-design/design-system/icons';
import {MapMarker} from '@mbank-design/design-system/icons';
import {ChevronDown} from '@mbank-design/design-system/icons';
import {ChevronUp} from '@mbank-design/design-system/icons';
import {IconColor} from '@mbank-design/design-system/enums';
import {Direction} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';

import { Stack, Button, Box, LinkBase, Avatar, Text } from '@mbank-design/design-system/components';
import { ButtonColor, LinkColor, TextAlign, Spacing } from '@mbank-design/design-system/enums';

const FooterListItem = styled.li`
	flex-basis: 25%;
	padding-left: ${px(Spacing.SPACE_12)};
	padding-right: ${px(Spacing.SPACE_12)};
	box-sizing: border-box;
	${props => {
		if (props.itemIndex === 0) {
			return `
				padding-left: 0;
			`;
		}
		if (props.itemIndex === 3) {
			return `
				padding-right: 0;
			`;
		}
	}}

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
		${props => {
			if (props.exp) {
				return `
					position: relative;	
				`;
			}
		}}
	}
`;
const GroupList = styled.ul`
	list-style: none;
	padding-top: ${px(Spacing.SPACE_20)};
	padding-left: ${px(Spacing.SPACE_0)};
	margin-top: ${px(Spacing.SPACE_20)};
	border-top: 1px solid grey;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-bottom: ${px(Spacing.SPACE_20)};
		${props => {
			if (props.exp === 'true' && !props.open) {
				return `
					height: ${px(Spacing.SPACE_0)};
					overflow: hidden;
					padding-bottom: ${px(Spacing.SPACE_0)};
				`;
			}
			if (props.exp === 'true' && props.open) {
				return `
					height: auto;
					overflow: hidden;
					padding-bottom: ${px(Spacing.SPACE_0)};
				`;
			}
		}}
	}
`;
const GroupListItem = styled.li`
	display: block;
	margin-bottom: ${px(Spacing.SPACE_0)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-bottom: ${px(Spacing.SPACE_4)};
	}
`;
const ChevronContainer = styled.div`
	display: none;
	position: absolute;
	top: ${px(Spacing.SPACE_8)};
	right: ${px(Spacing.SPACE_16)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
	}
`;
const LinkWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const CustomText = styled(Text)`
  height: 36px;
  display: flex;
  align-items: center;
  margin: 0;
`;

const StyledLink = styled.a`
	display: block;
	height: 36px;
	padding-top: 5px;
	color: ${palette.zeusBlackUI};
`;
const linkStyles = `
	display: block;
	height: 36px;
	padding-top: 5px;
	color: ${palette.zeusBlackUI};
`;
const CustomLink = styled(Link)`
  height: 36px;
  padding-top: 5px;
  display: block;
  font-weight: 400;
`;
const Redirect = {
	"aktualnosci/": 'aktualnosci/',
	"biznes/": 'biznes/'
  };
  const OuterRedirect = {
	"outer/": 'https://outer.com/',
  };
function NavFooter(params) {
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log('Build Nav')
  }
  let [navData, setNavData] = useState("");
  useParams();
  let [jsonStatus, setJsonStatus] = useState(null);
  useEffect(() => {
    let fetchNavs = async () => {
      let url = null;
      if (window.location.origin.includes(environment.cmsName)) {
        url = window.location.origin + environment.navJson;
      }
      else if (window.location.origin.includes(environment.localhost)) {
        url = environment.siteUrlDev + environment.navJson;
      }
      else {
        url = window.location.origin + environment.navJson;
      }
      let response = "";
      try {
        response = await Axios(url);
        setJsonStatus(true);
      } catch (err) {
        setJsonStatus(false);
        response = "error";
        console.log('error get nav: ' + err)
      }
      if (response.hasOwnProperty('data')) {
        setNavData(response.data);
      }
      else {
        setJsonStatus(false);
      }
    };
    fetchNavs();
  },[]);
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log(navData);
  }
  function RedirectLink(item) {
    if (item.line === 'online') {
      if (Redirect.hasOwnProperty(item.link)) {
        return (
          <Link to={Redirect[item.link]} className="menu-item-href">
            {item.text}
          </Link>
        )
      }
      else {
        return (
          <Link to={item.link} className="menu-item-href">
            {item.text}
          </Link>
        )
      }
    }
    else {
      if (Redirect.hasOwnProperty(item.link)) {
        return (
          <a href={`/`+Redirect[item.link]} className="menu-item-href">
            {item.text}
          </a>
        )
      }
      else {
        return (
          <a href={`/`+item.link} className="menu-item-href">
            {item.text}
          </a>
        )
      }
    }
  }
 
  function RenderNavs() {
    return (
    params.line === "offline"
    ? navData.map((item, i) => {
      if (item.children.length > 0) {
        return (
          <FooterListItem itemIndex={i} exp={true} key={'flist-'+i}>
              <CustomText as="h3" className="group-title">{item.text}</CustomText>
              <GroupList className="group" open={true} exp={true}>{item.children.length > 0 && item.children.map((link, j) => {
                return (<GroupListItem key={'footer-link' + j}>
                  <CustomLink
                    href={'/'+link.path}
                    color={LinkColor.MONO_900}
                  >
                    {link.text}
                  </CustomLink>	
                </GroupListItem>);
              })}</GroupList>
          </FooterListItem>
        )
      }
    })
    : navData.map((item, i) => {
        if (item.children.length > 0) {
          return (
            <FooterListItem itemIndex={i} exp={true} key={'flist-'+i}>
              <CustomText as="h3" className="group-title">{item.text}</CustomText>
              <GroupList className="group" open={true} exp={true}>{item.children.length > 0 && item.children.map((link, j) => {
                return (<GroupListItem key={'footer-link' + j}>
                  <CustomLink
                    component={ReactRouterLink}
                    to={'/'+link.path}
                    color={LinkColor.MONO_900}
                  >
                    {link.text}
                  </CustomLink>	
                </GroupListItem>);
              })}</GroupList>
            </FooterListItem>
          )
        }
      })
    )
  }

  switch (jsonStatus) {
    case null:
      return <li className="Loading-nav b-white ml-10"><Loaders type="dots" color="b-red"/></li>;
    case false:
      return <li className="Loading-nav b-red"><a href="/" className="menu-item-href">Strona Główna</a></li>;
    case true:
      return (
        <>
          <RenderNavs/>
        </>
      );
    default:
      break;
  }
};
export default NavFooter;