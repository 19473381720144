import React from "react";
import { environment } from "./environment";
import Svg from "./components/Svg";

import styled from 'styled-components';
import NavFooter from "./NavFooter.js";
import { LinkColor } from '@mbank-design/design-system/enums';
import {MbankSquareCorporate as LogoMob} from '@mbank-design/design-system/logos';
import { Icon, Link } from '@mbank-design/design-system/components';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Box, Container } from '@mbank-design/design-system/components';
import { Text } from '@mbank-design/design-system/components';
import { Image } from '@mbank-design/design-system/components';
import { Spacing } from '@mbank-design/design-system/enums';
import { px } from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';

function Foo(params) {
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log('Footer');
    console.log(params);
  }
  let hostname = '';
  if (window.location.origin.includes(environment.cmsName)) {
    hostname = window.location.origin;
  }
  else if (window.location.origin.includes(environment.localhost)) {
    hostname= environment.siteUrlDev;
  }
  else {
    hostname = window.location.origin;
  }
  const FooterList = styled.ul`
	display: flex;
	width: 100%;
	list-style: none;
	justify-content: space-between;
	padding-top: ${px(Spacing.SPACE_24)};
	padding-bottom: ${px(Spacing.SPACE_24)};
	padding-left: ${px(Spacing.SPACE_0)};
	margin-top: ${px(Spacing.SPACE_0)};
	margin-bottom: ${px(Spacing.SPACE_0)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		padding-top: ${px(Spacing.SPACE_40)};
		padding-bottom: ${px(Spacing.SPACE_40)};
	}
`;
const BottomContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: ${px(Spacing.SPACE_40)};
	padding-bottom: ${px(Spacing.SPACE_40)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
	}
`;
const BottomLogo = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const SocialMediaLinks = styled.div`
	display: flex;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		order: 0;
		margin-bottom: ${px(Spacing.SPACE_16)};
	}
`;
const OrderedBox = styled.div`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		order: 1;
	}
`;
const qrqsLinkStyles = `
	color: ${palette.zeusBlackUI};
	font-weight: normal;
	padding-left: 4px;
	&:hover {
		text-decoration: none;
	}
`;
const CustomContainer = styled(Container)`
	max-width: ${px(1315)} !important;
`;

  return (
    <Box as="div" className="outer" marginTop={Spacing.SPACE_24}>
      <CustomContainer>
        <CustomContainer background={palette.wildSandGray}>
          <Box as="nav" className="nav">
            <FooterList>
              <NavFooter line={params.line}/>
            </FooterList>
          </Box>
        </CustomContainer>
      </CustomContainer>
      <Box as="div" className="outer">
        <CustomContainer>
          <BottomContainer>
            <OrderedBox>
              <Text as="small" color="doveGrayUI">
                <Link
                  href={environment.qrqsLink}
                  color={LinkColor.MONO_900}
                >
                  @2023 {environment.projectName} S.A. / Powered by&nbsp;
                  <Image
                    src={hostname+environment.iconsFolder+'qrqs_logo.svg'}
                    alt="Quercus – Software on demand, on time"
                    title="Quercus – Software on demand, on time"
                  />
                </Link>	
              </Text>
            </OrderedBox>
            <SocialMediaLinks>
              <BottomLogo>
                <Text as="small" color="doveGrayUI">Jesteśmy w grupie mBanku</Text>
                <a href="https://mbank.pl/"><LogoMob width="40" height="30" title="logo mbank" />  </a>
              </BottomLogo>
            </SocialMediaLinks>
          </BottomContainer>
        </CustomContainer>
      </Box>
    </Box>
  );
}

export default Foo;