import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
/*import { Link } from "react-router-dom";*/
import { environment } from "./environment";
import Loaders from "./Loaders.js";

import styled from 'styled-components';
import {Stripe} from '@mbank-design/design-system/components';
import {Icon, Link, Container} from '@mbank-design/design-system/components';
import { Link as ReactRouterLink } from 'react-router-dom';
import {IconColor} from '@mbank-design/design-system/enums';
import {ButtonBase} from '@mbank-design/design-system/components';
import {Text} from '@mbank-design/design-system/components';
import {HamburgerMenu} from '@mbank-design/design-system/icons';
import {Close} from '@mbank-design/design-system/icons';
import {ChevronDown} from '@mbank-design/design-system/icons';
import {ChevronUp} from '@mbank-design/design-system/icons';
import {ChevronLeft} from '@mbank-design/design-system/icons';
import {ChevronRight} from '@mbank-design/design-system/icons';
import {StripeHeight, LinkColor} from '@mbank-design/design-system/enums';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';


function Nav(params) {
	if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
		console.log('Build Nav')
	}

	let [navData, setNavData] = useState(""),
		[jsonStatus, setJsonStatus] = useState(null),
		[showSubmenu, setShowSubmenu] = useState(false),
		[submenuIndex, setSubmenuIndex] = useState(0);
	useParams();
  
const MainNav = styled.nav`
display: flex;
align-items: center;
padding-bottom: ${px(Spacing.SPACE_16)};
padding-top: ${px(Spacing.SPACE_16)};
max-width: ${px(1315)};
margin: 0 auto;

@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	padding-right: ${px(Spacing.SPACE_16)};
	padding-left: ${px(Spacing.SPACE_16)};
}
`;
const NavToggle = styled.div`
display: none;
justify-content: center;
align-items: center;
height: ${px(Spacing.SPACE_50)};
width: ${px(Spacing.SPACE_50)};
margin-left: auto;
cursor: pointer;

@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	display: flex;
	position: absolute;
	right: ${px(Spacing.SPACE_16)};
}
`;
const Menu = styled.ul`
list-style: none;
display: flex;
margin-right: auto;
padding-left: ${px(Spacing.SPACE_56)};

@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1285px) {
	padding-left: ${px(Spacing.SPACE_24)};
}
@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1130px) {
	padding-left: 14px;
}
@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	flex-direction: column;
	width: 100%;
	padding-right: ${px(Spacing.SPACE_16)};
	padding-left: ${px(Spacing.SPACE_16)};
	margin-top: ${Spacing.SPACE_0};
	margin-bottom: ${Spacing.SPACE_0};
}
`;
const MenuItem = styled.li`
margin-left: ${props => props.marginLeft || px(Spacing.SPACE_12)};
margin-right: ${props => props.marginRight || px(Spacing.SPACE_12)};
margin-bottom: ${props => props.marginBottom || 0};

@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1130px) {
	margin-left: 10px;
	margin-right: 10px;
	
}
@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	position: relative;
	margin-right: ${Spacing.SPACE_0};
	margin-left: ${Spacing.SPACE_0};
	margin-bottom: ${Spacing.SPACE_0};
	padding-top: ${px(Spacing.SPACE_12)};
	padding-bottom: ${px(Spacing.SPACE_12)};
	border-bottom: 1px solid ${palette.altoGray};
	text-align: center;
}
`;
const MenuItemSub = styled.li`
margin-left: ${props => props.marginLeft || px(Spacing.SPACE_12)};
margin-right: ${props => props.marginRight || px(Spacing.SPACE_12)};
margin-bottom: ${props => props.marginBottom || 0};
margin-top: ${props => props.marginRight || px(Spacing.SPACE_8)};

@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1130px) {
	margin-left: 10px;
	margin-right: 10px;
	
}
@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	position: relative;
	margin-right: ${Spacing.SPACE_0};
	margin-left: ${Spacing.SPACE_0};
	margin-bottom: ${Spacing.SPACE_0};
	padding-top: ${px(Spacing.SPACE_12)};
	padding-bottom: ${px(Spacing.SPACE_12)};
	border-bottom: 1px solid ${palette.altoGray};
	text-align: center;
}
`;
// toggle visibility based on component props
const SubmenuLayer = styled.div`
${props => {
	if (props.show) {
		return `
			display: block;
			position: absolute;
			top: ${px(Spacing.SPACE_80)};
			margin-left: -24px;
			width: 100%;
			z-index: 2;
			padding-top: ${px(Spacing.SPACE_20)};
			padding-bottom: ${px(Spacing.SPACE_40)};
			background-color: ${palette.solidWhite};
		`;
	} else {
		return `
			display: none;
			position: absolute;
			top: ${px(Spacing.SPACE_84)};
			margin-left: -26px;
			width: 100%;
			z-index: 2;
			padding-top: ${px(Spacing.SPACE_20)};
			padding-bottom: ${px(Spacing.SPACE_40)};
			background-color: ${palette.solidWhite};
		`;
	}
}}

&::after {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: #fff;
	top: 0;
	left: -100%;
}
@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	position: fixed;
	top: ${px(Spacing.SPACE_67)};
	bottom: ${px(Spacing.SPACE_0)};
	padding-left: ${px(Spacing.SPACE_16)};
	padding-right: ${px(Spacing.SPACE_16)};
	margin-left: 0;
}
`;
const Overlay = styled.div`
${props => {
	if (props.show) {
		return `
			display: block;
			position: fixed;
			top: ${px(Spacing.SPACE_84)};
			right: ${px(Spacing.SPACE_0)};
			left: ${px(Spacing.SPACE_0)};
			z-index: 1;
			height: ${props.siteHeight + 'px'};
			background-color: ${palette.zeusBlackUI};
			opacity: .2;
		`;
	} else {
		return `
			display: none;
			position: absolute;
			top: ${px(Spacing.SPACE_84)};
			right: ${px(Spacing.SPACE_0)};
			left: ${px(Spacing.SPACE_0)};
			z-index: 1;
			height: ${props.siteHeight + 'px'};
			background-color: ${palette.zeusBlackUI};
			opacity: .2;
		`;
	}
}}

@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	top: 0;
}
`;
const Submenu = styled.ul`
list-style: none;
padding-left: ${px(Spacing.SPACE_0)};

@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	display: ${showSubmenu ? 'block' : 'none'};
}
`;
const CustomLink = styled(Link)`
  display: block;
  font-weight: 400;
`;
const SubmenuWrapper = styled.div`
display: flex;

@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	flex-wrap: wrap;
	padding-left: ${px(Spacing.SPACE_0)};
}
`;
const CustomContainer = styled(Container)`
	max-width: ${px(1315)} !important;
	padding: 0 12px;
	margin-left: 0;
`;
const SubmenuContainer = styled.div`
position: relative;
margin: 0 auto;
`;
const ButtonContent = styled.span`
position: relative;
display: flex;
color: ${props => props.color};
padding-right: ${px(Spacing.SPACE_20)};
`;
const MobileButtonContent = styled(ButtonContent)`
display: none;

@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	display: block;
}
`;
const IconWrapper = styled.div`
position: absolute;
left: calc(100% - ${px(Spacing.SPACE_20)});

@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	display: block;
	transform: rotate(-90deg);
}
`;
const MobileIconWrapper = styled.div`
position: absolute;
display: none;
left: calc(100% - ${px(Spacing.SPACE_24)});
top: ${px(Spacing.SPACE_12)};

@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	display: block;
}
`;
const SubmenuColumn = styled.div`
flex-basis: 50%;

@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	flex-basis: 100%;
}
`;
const SubmenuTitle = styled.div`
display: inline-block;
margin-bottom: ${px(Spacing.SPACE_24)};
padding-bottom: ${px(Spacing.SPACE_12)};
border-bottom: ${px(2)} solid ${palette.endeavourBlueUI};

@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	display: none;
}
`;
const MobileSubmenuTitle = styled.div`
display: none;
margin-right: ${Spacing.SPACE_0};
margin-left: ${Spacing.SPACE_0};
padding-top: ${px(Spacing.SPACE_12)};
padding-bottom: ${px(Spacing.SPACE_12)};
border-bottom: 1px solid ${palette.altoGray};

@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	position: relative;
	display: ${props => props.open ? 'block' : 'none'};
}
`;
const MobileBackLink = styled.div`
display: none;

@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	display: block;
}
`;
const NavWrapper = styled.div`
display: flex;
flex-grow: 1;

@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1285px) {
	font-size: 0.8em;
}
@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1130px) {
	font-size: 0.7em;
}
@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	display: ${props => props.open ? 'flex' : 'none'};
	flex-direction: column;
	position: absolute;
	top: ${px(93)};
	left: 0;
	right: 0;
	height: calc(100vh - ${props => props.offline ? px(145) : px(93)});
	background-color: ${palette.solidWhite};
	z-index: 2;
}
`;
const NavButton = styled(ButtonBase)`
font-weight: 600;
font-family: Montserrat,Arial,sans-serif;

@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
	width: 100%;
	text-align: left;
}
`;

const Redirect = {
"aktualnosci/": 'aktualnosci/',
"biznes/": 'biznes/'
};
const OuterRedirect = {
"outer/": 'https://outer.com/',
};



  useEffect(() => {
    let fetchNavs = async () => {
      let url = null;
      if (window.location.origin.includes(environment.cmsName)) {
        url = window.location.origin + environment.navJson;
      }
      else if (window.location.origin.includes(environment.localhost)) {
        url = environment.siteUrlDev + environment.navJson;
      }
      else {
        url = window.location.origin + environment.navJson;
      }
      let response = "";
      try {
        response = await Axios(url);
        setJsonStatus(true);
      } catch (err) {
        setJsonStatus(false);
        response = "error";
        console.log('error get nav: ' + err)
      }
      if (response.hasOwnProperty('data')) {
        setNavData(response.data);
      }
      else {
        setJsonStatus(false);
      }
    };
    fetchNavs();
  },[]);
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log(navData);
  }
  function RedirectLink(item) {
    if (item.line === 'online') {
      if (Redirect.hasOwnProperty(item.link)) {
        return (
		<a href={`/`+Redirect[item.link]} className="menu-item-href">
            {item.text}
          </a>
        )
      }
      else {
        return (
			<a href={`/`+Redirect[item.link]} className="menu-item-href">
				{item.text}
			</a>
        )
      }
    }
    else {
      if (Redirect.hasOwnProperty(item.link)) {
        return (
          <a href={`/`+Redirect[item.link]} className="menu-item-href">
            {item.text}
          </a>
        )
      }
      else {
        return (
          <a href={`/`+item.link} className="menu-item-href">
            {item.text}
          </a>
        )
      }
    }
  }
  	function toggleSubmenu(e, i) {
		if (showSubmenu) {
			setShowSubmenu(false);
		} else {
			setShowSubmenu(e, i);
			setSubmenuIndex(i);
		}
	}
	function closeSubmenu(e, i) {
		if (showSubmenu) {
			setShowSubmenu(false);
		}
	}
  function RenderNavs() {
    return (
    params.line === "offline"
    ? navData.map((item, i) => {
      return (
		<MenuItem key={'menu-item-' + i}>
            <NavButton buttonType="tertiary">
				<ButtonContent
					color={palette.zeusBlackUI}
					style={{justifyContent: 'flex-start', textAlign: 'left', paddingRight: (item.children.length > 0 ? 20 : 0 )}}
				>
                <CustomLink href={'/'+item.path} color={LinkColor.MONO_900} onClick={(e) => closeSubmenu(e, i)}>
					{item.text}
				</CustomLink>
                {item.children.length > 0 &&
					<IconWrapper onClick={(e) => toggleSubmenu(e, i)} >
						<Icon
							iconComponent={showSubmenu && submenuIndex === i ? ChevronUp : ChevronDown}
							primaryColor="secondary_contrast"
							title="ikona"
						/>
					</IconWrapper>
				}
              </ButtonContent>
            </NavButton>
            {item.children.length > 0 &&
				<>
					<SubmenuLayer className="submenu-layer" show={showSubmenu && submenuIndex === i}>
						<Container>
							<SubmenuWrapper index={i}>
								<SubmenuColumn>
									<Submenu className="submenu">
									{item.children && item.children.map((subitem, ii) => {
										return (
										<MenuItemSub key={'submenu-item-' + i + '_' + ii}>
											<NavButton buttonType="tertiary">
												<CustomLink href={'/'+subitem.path} color={LinkColor.MONO_900} onClick={(e) => closeSubmenu(e, i)}>
													{subitem.text}
												</CustomLink>
											</NavButton>
										</MenuItemSub>
										) 
									})}
									</Submenu>
								</SubmenuColumn>
							</SubmenuWrapper>
						</Container>
					</SubmenuLayer>
					<Overlay
						className="overlay"
						siteHeight={window.innerHeight}
						show={showSubmenu && submenuIndex === i}
						onClick={(e) => closeSubmenu(e, i)}
					>
					</Overlay>
				</>
			}
          </MenuItem>
	  )
        /*<li id={`menu-item-`+i} key={`menu-item-`+i} className="dropdown active">
          <RedirectLink link={item.path} text={item.text} line="offline"/>
          {item.children &&
              (<ul className="submenu">
                {item.children.map((subitem, ii) => {
                  return (<li id={`menu-item-`+i+'_'+ii} key={`menu-item-`+i+'_'+ii} className="dropdown">
                    <a href={`/`+subitem.path} className="menu-item-href">
                      {subitem.text}
                    </a>
                  </li>)
                })}
              </ul>)
            }
        </li>)*/
      })
    : navData.map((item, i) => {
        return (
          <MenuItem key={'menu-item-' + i}>
            <NavButton buttonType="tertiary">
              <ButtonContent
                color={palette.zeusBlackUI}
                style={{justifyContent: 'flex-start', textAlign: 'left', paddingRight: (item.children.length > 0 ? 20 : 0 )}}
              >
                <CustomLink
					component={ReactRouterLink}
					to={'/'+item.path}
					color={LinkColor.MONO_900}
					onClick={(e) => closeSubmenu(e, i)}
				>
					{item.text}
				</CustomLink>
                {item.children.length > 0 &&
					<IconWrapper onClick={(e) => toggleSubmenu(e, i)} >
						<Icon
							iconComponent={showSubmenu && submenuIndex === i ? ChevronUp : ChevronDown}
							primaryColor="secondary_contrast"
							title="ikona"
						/>
					</IconWrapper>
				}
              </ButtonContent>
            </NavButton>
            {item.children.length > 0 &&
				<>
					<SubmenuLayer className="submenu-layer" show={showSubmenu && submenuIndex === i}>
						<CustomContainer>
							<SubmenuWrapper index={i}>
								<SubmenuColumn>
									<MobileBackLink>
										<NavButton buttonType="tertiary" onClick={(e) => toggleSubmenu(e, i)} >
											<MobileIconWrapper
												style={{left: '-7px', top: '2px'}}
											>
												<Icon
													iconComponent={ChevronLeft}
													primaryColor={IconColor.BLUE}
													title="ikona"
												/>
											</MobileIconWrapper>
											<MobileButtonContent
												color={palette.endeavourBlueUI}
												style={{justifyContent: 'flex-start', textAlign: 'left', paddingLeft: '16px'}}
											>
												powrót
											</MobileButtonContent>
										</NavButton>
									</MobileBackLink>
									<Submenu className="submenu">
										{item.children && item.children.map((subitem, ii) => {
											return (
											<MenuItemSub key={'submenu-item-' + i + '_' + ii}>
												<NavButton buttonType="tertiary">
													<CustomLink
														component={ReactRouterLink}
														to={'/'+subitem.path}
														color={LinkColor.MONO_900}
														onClick={(e) => closeSubmenu(e, i)}
													>
														{subitem.text}
													</CustomLink>													
												</NavButton>
											</MenuItemSub>
											) 
										})}
									</Submenu>
								</SubmenuColumn>
							</SubmenuWrapper>
						</CustomContainer>
					</SubmenuLayer>
					<Overlay
						className="overlay"
						siteHeight={window.innerHeight}
						show={showSubmenu && submenuIndex === i}
						onClick={(e) => closeSubmenu(e, i)}
					>
					</Overlay>
				</>
			}
          </MenuItem>
          /*<li id={`menu-item-`+i} key={`menu-item-`+i+'_'+i} className="dropdown">
            <RedirectLink link={item.path} text={item.text} line="online"/>
            {item.children &&
              (<ul className="submenu">
                {item.children.map((subitem, ii) => {
                  if (OuterRedirect.hasOwnProperty(subitem.path)) {
                    return (<li id={`menu-item-`+i+'_'+ii} key={`menu-item-`+i+'_'+ii} className="dropdown">
                      <a href={OuterRedirect[subitem.path]} target="_blank" rel="noreferrer" className="menu-item-href">
                        {subitem.text}
                      </a>
                    </li>)
                  }
                  else {
                    return (<li id={`menu-item-`+i+'_'+ii} key={`menu-item-`+i+'_'+ii} className="dropdown">
                      <Link to={subitem.path} className="menu-item-href">
                        {subitem.text}
                      </Link>
                    </li>)
                  }
                })}
              </ul>)
            }
          </li>*/
          )
      })
    )
  }

  switch (jsonStatus) {
    case null:
      return <li className="Loading-nav b-white ml-10"><Loaders type="dots" color="b-red"/></li>;
    case false:
      return <li className="Loading-nav b-red"><a href="/" className="menu-item-href">Strona Główna</a></li>;
    case true:
      return (
        <>
          <RenderNavs/>
        </>
      );
    default:
      break;
  }
};
export default Nav;