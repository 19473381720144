import React from "react";
import { environment } from "./../../environment";
import TextLinks from "../../components/TextLinks";
import styled from 'styled-components';
import { px } from "@mbank-design/design-system/utils";
import { Spacing } from "@mbank-design/design-system/enums";
import {Box, Container, Text} from '@mbank-design/design-system/components';

const CustomContainer = styled(Container)`
    max-width: ${px(1315)} !important;
`;

function TextBox(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('TextBox')
        console.log(params)
    }

    return (
        <Box as="section" className="text-box" marginTop={Spacing.SPACE_24}>
            <CustomContainer>
                {params.block['en']['Title'] &&
                    <Text as="h2">{params.block['en']['Title']}</Text>
                }
                
                {params.block['en']['Text'] && 
                    <div className="text-box">
                        <TextLinks text={params.block['en']['Text']} line={params.line} />
                    </div>
                }   
            </CustomContainer>
        </Box>
    );
}

export default TextBox;