import { environment } from "./../environment";

const generateImageUrl = (params) => {
  let image = '';

    if (params !== "") {
        if (params.hasOwnProperty('link')) {
            image = params.link;
        } else if (params.hasOwnProperty('path')) {
            image = params.path;
        } else {
            image = params;
        }
    }

    if (image.includes(environment.siteSites)) {
        image = image.split(environment.siteSites);
        image = image[1];
    }

    if ( window.location.origin.includes(environment.localhost) && !(image.includes("http://") || image.includes("https://")) ) {
        image = environment.siteUrlDev + image;
    }
    else if ( window.location.origin.includes(environment.cmsName) && !(image.includes("http://") || image.includes("https://")) ) {
        image = window.location.origin+image
    }

    if (params.width !== "" && params.width) {
        image = image + '_width=' + params.width;
    }
    
    return image
}

export default generateImageUrl