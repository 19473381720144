import React from "react";
import { environment } from "./../../environment";

function TopBannerCarouselItemItem(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('TopBannerCarousel Item Item' )
        console.log(params)
    }
    let colorActive = "1";
    if (params.SlideNumber * 1 !== params.SlideActive * 1) {
        colorActive = "0.4";
    }

    function changeSlider() {
        if (params.SlideNumber !== params.SlideActive) {
            params.ChangeSlide(params.SlideNumber)
        }
    }

    return (
        <div className="bottom-carousel d-flex">
            <div className="one-title-carousel f-column d-flex ">
                <div className="chip-tag d-flex f-row">
                    <span className="tag d-flex ai-center" style={{ opacity: colorActive }}>{params.SlideLabel}</span>
                </div>
                <div className="main-text mb-30">
                    <h2 onClick={changeSlider} style={{ opacity: colorActive }}>{params.SlideTitle}</h2>
                </div>
            </div>
        </div>
    )
}

export default TopBannerCarouselItemItem;