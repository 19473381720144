import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";
import App from "./Main";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop"
import Cookies from "./Cookies.js";
import {
  DesignSystemThemeProvider,
  DesignSystemOverlayProvider,
  DesignSystemSnackbarProvider,
} from '@mbank-design/design-system/providers';
import {mBankCorporate} from '@mbank-design/design-system/themes';
import { GlobalStyles, CarouselGlobalStyles } from '@mbank-design/design-system/styles';
import { Box } from '@mbank-design/design-system/components';
import styled from 'styled-components';

const HeaderContainer = styled(Box)`
	z-index: 3;
	position: fixed;
  top: 0;
	width: 100%;
	background-color: #fff;
`;
const MainContainer = styled(Box)`
	margin-top: 112px;
`;
const container = document.getElementById("page-wrapper");
const root = createRoot(container);
root.render(
  <DesignSystemThemeProvider theme={mBankCorporate}>
    <DesignSystemOverlayProvider>
      <DesignSystemSnackbarProvider>
        <CarouselGlobalStyles />
        <GlobalStyles />
        <BrowserRouter>
          <ScrollToTop />
          <HeaderContainer
            as="header"
            id="header"
            className="header"
          >
            <Header line="online"/>
          </HeaderContainer>
          <Routes>
            <Route path="*" element={
              <MainContainer
                as="main"
                id="main"
                className="main"
              >
                <App />
              </MainContainer>
            }/>
          </Routes>
          <footer id="footer">
            <Footer line="online"/>
          </footer>
          <Cookies type="policy"/>
        </BrowserRouter>
      </DesignSystemSnackbarProvider>
    </DesignSystemOverlayProvider>
  </DesignSystemThemeProvider>
);