import React, {useState} from 'react';
import styled from 'styled-components';
import { environment } from "./../../environment";
import OurButton from '../../components/Button';
import Links from '../../components/Links';
import { Box, Text, Image, ButtonLayout, Container, Stack, Link } from '@mbank-design/design-system/components';
import { Spacing, Alignment } from '@mbank-design/design-system/enums';
import { px } from '@mbank-design/design-system/utils';
import generateImageUrl from './../../utils/GenerateImageUrl';

const CustomContainerBanner = styled(Container)`
    max-width: ${props => props.outerBackgrounds ? "100% !important" : "1315px !important" };
    padding: ${props => props.outerBackgrounds ? "0 !important" : ""};
`

const Gradient = styled(Box)`
    width: 100%;
    height: ${props => props.bigHeight ? "540px" : "400px"};
    position: relative;

    &::after {
		content: '';
		display: ${props => props.titleOverImage ? 'none' : 'block'};
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0) linear-gradient(to left, transparent, rgb(0, 0, 1) 90%, rgb(0, 0, 0)) repeat scroll 0% 0%;
	}

    @media screen and (max-width: ${px(660)}) {
		&::after {
			background: rgba(0, 0, 0, 0) linear-gradient(to bottom, transparent, rgb(0, 0, 1) 90%, rgb(0, 0, 0)) repeat scroll 0% 0%;
		}
	}
`
const TextGroup = styled(Box)`
    position: ${props => props.image ? 'absolute' : 'relative'};
	top: 0;
	bottom: 0;
	left: 50%;
	left: ${props => props.image ? '50%' : 'auto'};
	transform: translate(${props => props.image ? '-50%' : '0'});
	z-index: 1;
	min-height: 100%;
	max-width: ${props => props.outerBackgrounds ? px(1315) : px(1215) };
	width: 100%;
	margin-top: ${Spacing.SPACE_0};
	margin-right: auto;
	margin-bottom: ${Spacing.SPACE_0};
	margin-left: auto;
    padding: 50px 24px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: column;
    gap: 20px;

    @media screen and (max-width: ${px(660)}) {
		background: rgba(0, 0, 0, 0) linear-gradient(to bottom, transparent, rgb(0, 0, 1) 90%, rgb(0, 0, 0)) repeat scroll 0% 0%;
	}
`;

const TextToButton = styled(Box)`
    margin-top: 10px;
`;

function Banner(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Banner')
        console.log(params)
    }

    const [imageBanner, setImageBanner] = useState(params.block['en']['Image']);
    
    window.addEventListener('resize', () => {
        if (window.innerWidth <= 660) {
            setImageBanner(params.block['en']['MobileImage']);
        } else {
			setImageBanner(params.block['en']['Image']);
		}
    });

    return (
        <Box as="section" className="banner">
            <CustomContainerBanner
                outerBackgrounds={params.block['en']['OuterBackground']}>
                <Gradient
                    bigHeight={params.block['en']['Text']}>
                    <Image alt={params.block['en']['AltText']} src={generateImageUrl(imageBanner)} objectFit="cover" width="100%" height="100%" />
                    <TextGroup
                        image={params.block['en']['Image']}
                        outerBackgrounds={params.block['en']['OuterBackground']}
                        >
                        <Text 
                            styledAs="h1"
                            as="h2" 
                            color="solidWhite"
                            style={{whiteSpace: 'pre-wrap'}}
                            dangerouslySetInnerHTML={
                                {__html: params.block['en']['Title']}
                            }>
                        </Text>
                        <Box maxWidth={"500px"}>
                            <Text 
                                styledAs="h4"
                                fontWeight="normal"
                                as="span"
                                color="solidWhite"
                                paddingTop={42}
                                dangerouslySetInnerHTML={
                                    {__html: params.block['en']['Text']}
                                }>
                            </Text>
                        </Box>
                        <ButtonLayout>
                            <Stack direction={['column', 'row']} alignX={Alignment.CENTER} alignY={Alignment.CENTER} spacing={16}>
                                {params.block['en']['Link'] !== "" && params.block['en']['LinkName'] !== "" &&
                                    <OurButton href={params.block['en']['Link']} addname={params.block['en']['LinkName']} />
                                }
                                {params.block['en']['BottomTextLink'] && params.block['en']['BottomText'] &&
                                // <TextToButton className="test">
                                //     <Links url={params.block['en']['BottomTextLink']} name={params.block['en']['BottomText']} target="true" />
                                // </TextToButton>
                                    <OurButton href={params.block['en']['BottomTextLink']} addtype={"tertiary"} addname={params.block['en']['BottomText']} addtarget="true" />
                                }
                            </Stack>
                        </ButtonLayout>
                    </TextGroup>
                </Gradient>
            </CustomContainerBanner>
        </Box>
    )
}

export default Banner;