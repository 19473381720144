import React from "react";
import { environment } from "./../../environment";
import {
  Box,
  Container,
  Text,
  Image,
  Carousel,
} from "@mbank-design/design-system/components";
import { palette } from "@mbank-design/design-system/palette";
import { Spacing } from "@mbank-design/design-system/enums";
import { px } from "@mbank-design/design-system/utils";
import breakpoints from "@mbank-design/design-system/styles/breakpoints";
import generateImageUrl from "../../utils/GenerateImageUrl";
import styled from "styled-components";
import Links from "../../components/Links";

const CustomContainer = styled(Container)`
  max-width: ${px(1315)} !important;
`;

const InnerDesktopFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  @media screen and (max-width: ${px(breakpoints.mobileMax)}) {
    display: none;
  }
`

const InnerDesktop = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(4, 1fr);
  @media screen and (max-width: ${px(breakpoints.mobileMax)}) {
    display: none;
  }
`;

const InnerMobile = styled.div`
  display: none;

  @media screen and (max-width: ${px(breakpoints.mobileMax)}) {
    display: block;
    padding-right: ${px(Spacing.SPACE_16)};
    padding-left: ${px(Spacing.SPACE_16)};
  }
`;

const InfoboxSet = (params) => {
  if (
    window.origin.includes(environment.localhost) ||
    window.origin.includes(environment.devName)
  ) {
    console.log("Infobox");
    console.log(params);
  }

  const InfoboxItem = ({ item, mobile, line, isLayout }) => {
    let altText = "";
    if (item.AltText) {
      altText = item.AltText;
    } else if (!item.AltText && item.Title) {
      altText = item.Title;
    } else {
      altText = "Mfaktoring image";
    }
  
    return (
      <Box
        background={palette.wildSandGray}
        padding={Spacing.SPACE_24}
        display="flex"
        flexDirection="column"
        alignItems={mobile ? "center" : "flex-start"}
        position="relative"
        width={`${isLayout ? "calc(33.3333% - 16px)" : ''}`}
      >
        {item.Link && 
        <Links 
          url={item.Link}
          target={`${item.InNewOpen ? "true" : "false"}`}
          allLink={true}
          line={line}
          ></Links>}
        {item.Image && (
          <Image
            src={generateImageUrl(item["Image"])}
            alt={altText}
            maxWidth="50px"
            minWidth="45px"
            title={altText}
            marginTop={Spacing.SPACE_24}
          />
        )}
        {item.Title && (
          <Box marginTop={!item.Image ? Spacing.SPACE_0 : Spacing.SPACE_24}>
            <Text
              as="h3"
              align={["center", "left"]}
              marginTop={!item.Image ? Spacing.SPACE_0 : Spacing.SPACE_16}
              marginBottom={Spacing.SPACE_0}
            >
              {" "}
              {item.Title}
            </Text>
          </Box>
        )}
        {item.Text && (
          <Text
            as="p"
            align={mobile ? "center" : "left"}
            dangerouslySetInnerHTML={{ __html: item.Text }}
          ></Text>
        )}
        {item.Link && item.LinkName && (
          <Box marginTop="auto">
            <Links
              url={item.Link}
              name={item.LinkName}
              title={item.LinkName}
              target={`${item.InNewOpen}`}
            />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box as="section" className="infobox-set" marginTop={Spacing.SPACE_24}>
      <CustomContainer>
        {params.block["en"].Title && (
          <Text as="h2" marginBottom={Spacing.SPACE_24}>
            {params.block["en"].Title}
          </Text>
        )}
        {params.block['en']?.Layout && 
          <InnerDesktopFlex>
            {params.block["en"]?.Item.map((item, index) => (
              <InfoboxItem item={item} key={index} line={params.line} isLayout={params.block['en'].Layout} />
            ))}
          </InnerDesktopFlex>
        }
        {!params.block['en']?.Layout && 
          <InnerDesktop>
            {params.block["en"]?.Item.map((item, index) => (
              <InfoboxItem item={item} key={index} line={params.line} isLayout={params.block['en'].Layout} />
            ))}
          </InnerDesktop>
        }
        <InnerMobile>
          <Carousel isNavigationShown={false}>
            {params.block["en"]?.Item.map((item, index) => (
              <InfoboxItem item={item} key={index} mobile={true} />
            ))}
          </Carousel>
        </InnerMobile>
      </CustomContainer>
    </Box>
  );
};

export default InfoboxSet;
