
import React from "react";
import { Link } from '@mbank-design/design-system/components';
import { Link as ReactRouterLink } from 'react-router-dom';
import { environment } from "./../environment";
import styled from "styled-components";

const AllLink = styled(Link)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`

const AllLinkRouter = styled(ReactRouterLink)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`

function Links(params) {
    let newtab = "";
    if (params.target === 'true' && params.target) {
        newtab = { 'target': '_blank' }
    }
    let title = "";
    if (params.title !== '' && params.title) {
        title = { 'title': params.title }
    }
    let url = "";
    let hrefOrLink = "";
    if (params.url !== "") {
        if (params.url.hasOwnProperty('link')) {
            url = params.url.link;
        } else if (params.url.hasOwnProperty('path')) {
            url = params.url.path;
        } else {
            url = params.url;
        }
    } else {
        url = "";
    }

    if (url.includes(environment.siteSites) && url !== "") {
        url = url.split(environment.siteSites);
        url = url[1];
        hrefOrLink = "isLink";
    } else if ((url.includes("http://") || url.includes("https://")) && url !== "") {
        if (url.includes("offline-") && params.line === 'online') {//for details broken link with no export/sites
            let uri = new URL(url);
            url = uri.pathname;
            hrefOrLink = "isLink";
        }
        else {
            hrefOrLink = "isHref";
        }
    } else {
        hrefOrLink = "isLink";
    }

    if (newtab !== "") {
        hrefOrLink = "isHref";
    }

    if(params.allLink) {
        return(
            (params.line === 'offline' || hrefOrLink === "isHref")
            ?
            <AllLink href={url} className={params.class} {...title} {...newtab}>{params.name}</AllLink> 
            :
            <AllLinkRouter to={url} className={params.class}>{params.name}</AllLinkRouter>
        )
    }

    return (
        (params.line === 'offline' || hrefOrLink === "isHref")
            ?
            <Link href={url} className={params.class} {...title} {...newtab}>{params.name}</Link> 
            :
            <ReactRouterLink to={url} className={params.class}>{params.name}</ReactRouterLink>
    )
}

export default Links;
